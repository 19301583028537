
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

interface ProfileDetails {
  avatar: string;
  fname: string;
  lname: string;
  phone: string;
  email: string;
  country: string;
  city: string;
  currency: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const email = ref<string>(
      store.getters.subscriberInfo.email
        ? store.getters.subscriberInfo.email
        : ""
    );
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label("First name"),
      lname: Yup.string().required().label("Last name"),
      city: Yup.string().required().label("City"),
      phone: Yup.string().required().label("Contact phone"),
      country: Yup.string().required().label("Country"),
      currency: Yup.string().required().label("Currency"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(4).required().label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const actualUserData = computed(() => {
      return {
        fname: store.getters.subscriberInfo?.fname ? store.getters.subscriberInfo?.fname : '',
        lname: store.getters.subscriberInfo?.lname ? store.getters.subscriberInfo?.lname : '',
        email: store.getters.subscriberInfo?.email ? store.getters.subscriberInfo?.email : '',
        phone: store.getters.subscriberInfo?.phone ? store.getters.subscriberInfo?.phone : '',
        city: store.getters.subscriberInfo?.city ? store.getters.subscriberInfo?.city : '',
        country: store.getters.subscriberInfo?.country ? store.getters.subscriberInfo?.country : ''
      };
    });

    const profileDetails = ref<ProfileDetails>({
      avatar: "media/avatars/blank.png",
      fname: store.getters.subscriberInfo.fname
        ? store.getters.subscriberInfo.fname
        : "",
      lname: store.getters.subscriberInfo.lname
        ? store.getters.subscriberInfo.lname
        : "",
      email: store.getters.subscriberInfo.email
        ? store.getters.subscriberInfo.email
        : "",
      phone: store.getters.subscriberInfo.phone
        ? store.getters.subscriberInfo.phone
        : "",
      country: store.getters.subscriberInfo.country
        ? store.getters.subscriberInfo.country
        : "RU",
      currency: store.getters.subscriberInfo.currency
        ? store.getters.subscriberInfo.currency
        : "",
      city: store.getters.subscriberInfo.city
          ? store.getters.subscriberInfo.city
          : "RU"
    });

    const saveChanges1 = () => {
      console.log('Save changes')
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        let sendData = actualUserData.value;

        setTimeout(() => {
          // Send login request
          store
              .dispatch(Actions.USER_SET_SETTINGS, sendData)
              .then(() => {
                Swal.fire({
                  text: t("processDone"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("good"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                })
                .then(() => window.location.reload())
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.settingsError,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });

          submitButton1.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton5.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Email is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            emailFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Password is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            passwordFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("settings"), [t("account")]);
    });

    const subscriberData = computed(() => {
      return store.getters.subscriberInfo;
    });

    const subscriberDataEmail = computed(() => {
      return store.getters.subscriberInfo.email
        ? store.getters.subscriberInfo.email
        : "-";
    });

    return {
      submitButton1,
      submitButton5,
      saveChanges1,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      removeImage,
      profileDetailsValidator,
      changeEmail,
      actualUserData,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
      subscriberData,
      subscriberDataEmail,
      email,
      t,
    };
  },
});
